<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <loading v-if="isLoading" />
    <stock-detail-modal
      :visible="openDetail"
      @close="openDetail = false"
      @change-page-stock-trx="onChangePageStockTrx"
      @change-period="onChangePeriod"
    />
    <StockSummaryWidget />
    <hr class="mt-5" />
    <div class="mb-5 rounded-lg">
      <div class="flex mt-4 flex-col">
        <div class="flex items-center justify-end space-x-2">
          <base-search v-model="search" @input="debounceSearch" />
          <export-dropdown />
        </div>
        <div class="flex my-2 sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <StockTable
              with-area-zero-price
              @detail="detailStock"
              @page-changed="onPageChangeProducts"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import { stockColumns } from '@/services/columns.service';
import StockDetailModal from '@/components/stock/StockDetailModal.vue';
import StockTable from '@/components/stock/stock-table.vue';
import BaseSearch from '@/components/base/Search.vue';
import StockSummaryWidget from '@/components/stock/StockSummaryWidget.vue';
import ExportDropdown from '@/components/stock/ExportDropdown.vue';

export default {
  name: 'Stocks',
  components: {
    StockDetailModal,
    StockTable,
    BaseSearch,
    StockSummaryWidget,
    ExportDropdown,
  },
  data() {
    return {
      stockColumns,
      search: null,
      openDetail: false,
      dropdown: false,
      dataStock: {},
      filter: {
        gudang: {
          id: '',
          code: '',
          name: '',
        },
      },
      currentSelectedStock: null,
      loadStockTrxParams: {
        pageNumber: 1,
      },
    };
  },
  computed: {
    ...mapGetters({
      getPeriod: 'periods/getPeriod',
      getStocksLoading: 'stocks/getLoading',
      getStockTrxesLoading: 'stock_trxes/getLoading',
      getPeriodsLoading: 'periods/getLoading',
      getLoadingTotalStockAvailable: 'periods/getLoadingTotalStockAvailable',
      me: 'auth/getUser',
    }),
    isLoading() {
      return (
        this.getStockTrxesLoading ||
        this.getStocksLoading ||
        this.getPeriodsLoading ||
        this.getLoadingTotalStockAvailable
      );
    },
    filteredData() {
      return this.getResults.filter((products) =>
        products.attributes.name
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions({
      fetchStocksByWarehouse: 'stocks/fetchStocksByWarehouse',
      fetchWarehouseById: 'warehouses/fetchWarehouseById',
      fetchStockById: 'stocks/fetchStockById',
      fetchStockTrxesByStock: 'stock_trxes/fetchStockTrxesByStock',
      fetchCurrentPeriod: 'periods/fetchCurrentPeriod',
    }),
    getProductCategory(id) {
      let category = this.getProducts.included.filter(function (el) {
        return el.id === id;
      });
      return category[0].attributes.name;
    },
    async loadStockTrx({ periodUuid } = { periodUuid: null }) {
      let currentPeriod;

      if (!periodUuid && !this.getPeriod.data.id) {
        currentPeriod = await this.fetchCurrentPeriod();
      } else {
        currentPeriod = {
          data: this.getPeriod,
        };
      }

      await this.fetchStockTrxesByStock({
        stockId: this.currentSelectedStock.id,
        params: {
          params: {
            'page[size]': 5,
            'page[number]': this.loadStockTrxParams.pageNumber,
            sort: 'latest',
            'filter[period_uuid]': periodUuid ?? currentPeriod.data.data.id,
          },
        },
      });
    },
    onChangePeriod(periodUuid) {
      this.loadStockTrx({ periodUuid });
    },
    onPageChangeProducts(page) {
      this.loadStocks({
        warehouse_id: this.filter.gudang.id,
        pageNumber: page,
        pageSize: 5,
        search: this.search,
      });
    },
    onChangePageStockTrx(page) {
      this.loadStockTrxParams.pageNumber = page;

      this.loadStockTrx();
    },
    debounceSearch: debounce(function () {
      this.loadStocks({
        warehouse_id: this.filter.gudang.id,
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
      });
    }, 300),
    async detailStock(stock) {
      this.currentSelectedStock = stock;
      this.loadStockTrxParams.pageNumber = 1;

      await Promise.all([
        this.fetchStockById({
          id: stock.id,
          params: {
            // 'fields[stocks]': 'product_code,product_name,stock_qty,is_orderable,is_pre_orderable,total_weight,license_status'
          },
        }),
        this.loadStockTrx(),
      ]);

      this.dataStock = stock;
      this.openDetail = !this.openDetail;
    },
    loadStocks(params) {
      this.fetchStocksByWarehouse({
        ...params,
        include: 'product.product-prices',
        'fields[stocks]':
          'product_code,product_name,stock_qty,stock_ordered,stock_real,product,free_qty,free_ordered,free_real',
      });
    },
  },
  created() {
    this.fetchWarehouseById({
      id: this.me.current_warehouse,
    }).then((response) => {
      if (response) {
        this.filter.gudang.id = response.data.data.id;
        this.filter.gudang.code = response.data.data.attributes.code;
        this.filter.gudang.name = response.data.data.attributes.name;
        this.loadStocks({
          warehouse_id: this.filter.gudang.id,
          pageNumber: 1,
          pageSize: 5,
        });
      }
    });
  },
};
</script>
