<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard title="Data Pembayaran">
      <div class="flex flex-col">
        <div class="flex items-center justify-end space-x-2">
          <select
            v-model="is_paid"
            @change="filterByPaid"
            class="inline-flex appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
          >
            <option :value="null">Semua</option>
            <option :value="true">Lunas</option>
            <option :value="false">Belum Lunas</option>
          </select>
          <base-search v-model="search" @input="debounceSearch" />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <KasirTable
              @click-row="redirectToDetail"
              @page-change="onPageChangeOrder"
            />
          </div>
        </div>
      </div>
    </BaseCard>
    <div v-if="isLoading" class="flex items-center text-center">
      <loading></loading>
    </div>
  </div>
</template>

<script>
import BaseSearch from '@/components/base/Search.vue';
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { StorageService } from '@/services/storage.service';
import BaseCard from '@/components/base/BaseCard.vue';
import KasirTable from '@/components/kasir/kasir-table.vue';

export default {
  name: 'Home',
  components: {
    BaseSearch,
    BaseCard,
    KasirTable,
  },

  data() {
    const origin_warehouse_id = StorageService.getUser().current_warehouse;
    return {
      search: null,
      openDetail: false,
      is_paid: false,
      data: {},
      origin_warehouse_id,
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      isLoading: 'orders/getLoading',
    }),
    orderParams() {
      return {
        'page[limit]': 5,
        'filter[is_paid]': this.is_paid,
        'filter[order_status]': 'Diproses Kasir',
        'filter[search]': this.search,
        'filter[origin_warehouse_id]': this.origin_warehouse_id,
        'filter[destination_office_category_id]': 5,
        fields: {
          orders:
            'destination_office_code,origin-warehouse,destination-office,is_paid,origin_code,origin_warehouse_code,is_valid_for_payment,final_price,payment_amount,canceled_by_user_id,order_status,canceled_from,grand_total_price',
          offices: 'code,is_active',
        },
        include: 'destination-office,origin-warehouse,order',
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      updateOrder: 'orders/updateOrder',
    }),
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.orderParams,
      });
    }, 300),
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...this.orderParams,
        pageNumber: page,
      });
    },
    filterByPaid() {
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    redirectToDetail(data) {
      this.$router.push(
        `pembayaran/faktur-penjualan/${data.attributes.order_id}`
      );
    },
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
  created() {
    this.fetchOrders({
      ...this.orderParams,
    });
  },
};
</script>
