<template>
  <div>
    <base-modal
      :showing="visible"
      size="max-w-5xl"
      @opened="onOpened"
      @close="$emit('close')"
    >
      <div class="space-y-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center space-x-4">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Daftar Barang
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Daftar Barang Yang Telah Ditambahkan
              </p>
            </div>
          </div>
        </div>
        <div class="space-y-4 border-t border-gray-200 pt-6">
          <div class="flex justify-end">
            <base-input
              placeholder="Cari Barang"
              type="search"
              :shadow="false"
              debounce
              v-model="filter.search"
              @native-input="loadProducts"
            />
          </div>
          <datatable
            :columns="columns"
            :scroll-x="false"
            :total="products.meta.page.total"
            :perPage="products.meta.page.perPage"
            :currentPage="products.meta.page.currentPage"
            @pagechanged="onPagechanged"
          >
            <template v-slot:tbody="{ classes }">
              <tr
                v-for="product in products.data"
                :key="product.id"
                :class="[
                  classes.tr,
                  'cursor-pointer bg-white hover:bg-green-100',
                ]"
                @click="$emit('click-product', product)"
              >
                <td :class="[classes.td, 'font-bold text-gray-900']">
                  {{ product.attributes.code }}
                </td>
                <td :class="[classes.td]">
                  {{ product.attributes.name }}
                </td>
                <td :class="[classes.td]">
                  {{ product.attributes.product_category_name }}
                </td>
              </tr>
            </template>
          </datatable>
        </div>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal';
import { requestMixin } from '@/mixins/request/request';

export default {
  components: {
    BaseModal,
  },
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    requestParams: null,
  },
  emits: ['close', 'click-product'],
  data() {
    return {
      products: {
        data: [],
        meta: {
          page: {},
        },
      },
      loading: false,
      filter: {
        search: null,
      },
    };
  },
  computed: {
    columns() {
      return [
        {
          id: 'code',
          name: 'Kode Barang',
        },
        {
          id: 'name',
          name: 'Nama Barang',
        },
        {
          id: 'category',
          name: 'Kelompok Barang',
        },
      ];
    },
  },
  methods: {
    async loadProducts(params) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/products', {
        params: {
          ...this.requestParams,
          'page[size]': 5,
          'fields[products]': 'code,name,product_category_code,product_category_name',
          'filter[search]': this.filter.search,
          ...params,
        },
      });

      if (!error) {
        this.products = res;
      }

      this.loading = false;
    },
    onOpened() {
      this.filter.search = null;

      this.loadProducts();
    },
    onPagechanged(page) {
      this.loadProducts({
        'page[number]': page,
      });
    },
  },
};
</script>
