<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard :title="title">
      <template #action>
        <base-button :to="to" type="button">
          <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
          Tambah {{ title }}
        </base-button>
      </template>

      <div class="flex flex-col">
        <div class="flex items-end justify-end space-x-2">
          <div class="relative mx-2 w-2/12 rounded-md shadow-sm">
            <label
              for="kantor_tujuan"
              class="mb-2 block text-sm font-medium text-gray-700"
              >Kantor Tujuan</label
            >
            <select
              id="order_type"
              v-model="filter.byOriginOfficeCategory"
              @change="handleChangeFilter"
              class="inline-flex w-full appearance-none justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm"
            >
              <option :value="null">Semua</option>
              <option value="1">Pusat</option>
              <option value="3">Stockist Center</option>
              <option value="2">Kantor Pelayanan</option>
            </select>
          </div>
          <div class="relative rounded-md shadow-sm">
            <div>
              <label
                for="status"
                class="mb-2 block text-sm font-medium text-gray-700"
                >Status</label
              >
              <select
                v-model="order_status"
                @change="filterByCompleted"
                class="focus:outline-none block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:ring-green-500 sm:text-sm"
              >
                <option :value="null">Semua</option>
                <option :value="{ 'filter[order_status]': 'Selesai' }">
                  Diterima
                </option>
                <option :value="{ 'filter[not-order_status]': 'Selesai' }">
                  Belum diterima
                </option>
              </select>
            </div>
          </div>
          <base-search
            v-model="search"
            @input="debounceSearch"
            placeholder="Cari Order Barang"
          />
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getOrders.meta.page.total"
              :perPage="getOrders.meta.page.perPage"
              :currentPage="getOrders.meta.page.currentPage"
              :meta="getOrders.meta"
              @pagechanged="onPageChangeOrder"
              :is-empty="getOrders.data?.length < 1"
              cursor
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      :key="item.label"
                      v-for="item in [
                        {
                          label: `Nomor ${title}`,
                        },
                        {
                          label: 'Kode Kantor',
                        },
                        {
                          label: 'Total Harga',
                          class: 'text-right',
                        },
                        {
                          label: 'Pembayaran',
                          class: 'text-right',
                        },
                        {
                          label: 'PV',
                          class: 'text-right',
                        },
                        {
                          label: 'BV',
                          class: 'text-right',
                        },
                        {
                          label: 'Status Laporan',
                        },
                        {
                          label: 'Status Faktur',
                        },
                      ]"
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      :class="item.class"
                    >
                      {{ item.label }}
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getOrders.data.length">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getOrders.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click.prevent="onClickRow(data)"
                  >
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                    >
                      <order-code-column :value="data" />
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getOrders.included.find(
                          (it) => it.id == data.attributes.order_id
                        ).attributes.origin_office_code
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      {{
                        getOrders.included.find(
                          (it) => it.id == data.attributes.order_id
                        ).attributes.final_price | toCurrency
                      }}
                    </td>
                    <td
                      :class="
                        getKekurangan(data) >= 0
                          ? 'text-green-500'
                          : 'text-red-500'
                      "
                      class="whitespace-nowrap px-6 py-4 text-right text-sm font-semibold text-gray-500"
                    >
                      {{
                        (getKekurangan(data) >= 0
                          ? getOrders.included.find(
                              (it) => it.id == data.attributes.order_id
                            ).attributes.payment_amount
                          : getKekurangan(data)) | toCurrency
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      {{
                        getOrders.included.find(
                          (it) => it.id == data.attributes.order_id
                        ).attributes.total_point_value
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                    >
                      {{
                        getOrders.included.find(
                          (it) => it.id == data.attributes.order_id
                        ).attributes.total_bonus_value | toCurrency
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                    >
                      <span
                        v-if="data.attributes.has_bundle"
                        class="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800"
                      >
                        Sudah Dilaporkan
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700"
                      >
                        Belum Dilaporkan
                      </span>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-xs text-gray-500"
                    >
                      <span
                        v-if="
                          getOrders.included.find(
                            (it) => it.id == data.attributes.order_id
                          ).attributes.is_valid_for_payment
                        "
                        class="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800"
                      >
                        Valid
                      </span>
                      <span
                        v-else-if="
                          !getOrders.included.find(
                            (it) => it.id == data.attributes.order_id
                          ).attributes.is_valid_for_payment &&
                          !getOrders.included.find(
                            (it) => it.id == data.attributes.order_id
                          ).attributes.canceled_by_user_id &&
                          !getOrders.included.find(
                            (it) => it.id == data.attributes.order_id
                          ).attributes.canceled_from
                        "
                        class="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700"
                      >
                        Draft
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-red-100 px-2 py-1 text-xs font-medium text-red-700"
                      >
                        Revisi
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>

        <OrderDetailModal
          title="Detail Pembelian"
          description="Detail Faktur Pembelian"
          :visible="openDetail"
          :order="dataPembelian"
          :deleteable="true"
          with-bonus
          @close="openDetail = !openDetail"
          @refresh="handleRefresh"
          @edit="handleEdit"
          with-stock-movement
          with-delete-confirmation
          @detail-stock-movement="handleDetailStockMovement"
        />

        <StockMovementModal
          :visible="openDetailStockMovement"
          :with-dispute="false"
          :with-confirm="false"
          :with-send="false"
          @close="handleCloseDetailStockMovement"
        />
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import OrderCodeColumn from '@/components/order/order-code-column.vue';
import StockMovementModal from '@/components/stock-movement/StockMovementModal.vue';
import { orderStatusMixin } from '@/mixins/order/order-status.mixin';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import BaseCard from '@/components/base/BaseCard.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    OrderDetailModal,
    OrderCodeColumn,
    StockMovementModal,
    BaseSearch,
    BaseButton,
    BaseCard,
  },
  mixins: [orderStatusMixin],
  props: {
    title: {
      type: String,
      default: 'Pembelian',
    },
    indexPath: String,
    viewAs: {
      default: 'stockist',
    },
    to: {
      default: 'pembelian/tambah-pembelian',
      type: [Object, String],
    },
    destinationOfficeCategoryId: Number
  },
  data() {
    return {
      search: null,
      order_status: null,
      openDetail: false,
      openDetailStockMovement: false,
      dataPembelian: { attributes: { is_valid_for_payment: null } },
      filter: {
        byOriginOfficeCategory: null,
      },
    };
  },
  created() {
    this.fetchOrders({
      ...this.orderParams,
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getPaymentPaymentMethods: 'payments/getPaymentMethods',
      isOrderLoading: 'orders/getLoading',
      isStockMovementLoading: 'stock_movements/getLoading',
    }),
    isLoading() {
      return this.isOrderLoading || this.isStockMovementLoading;
    },
    orderParams() {
      const params = {
        'filter[order_type]': 'purchase',
        'filter[origin_office_category_id]': this.filter.byOriginOfficeCategory,
        'filter[destination_office_category_id]': this.destinationOfficeCategoryId,
        include: 'payments,order',
        fields: {
          orders:
            'createdAt,origin_code,origin_office_code,destination_code,total_point_value,total_bonus_value,payment_amount,final_price,is_valid_for_payment,is_valid_for_packing,is_valid_for_shipment,is_shipped,order_status,has_follow_up_delivery,is_pending_follow_up_delivery,destination_office_code,origin_warehouse_code,order_status,updatedAt',
          payments: 'total_amount',
        },
        'filter[destination_warehouse_id]':
          StorageService.getUser().current_warehouse,
        'page[limit]': 5,
        'filter[search]': this.search,
        ...this.order_status,
      };
      return {
        ...params,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      fetchStockMovementsByOrder: 'stock_movements/fetchStockMovementsByOrder',
      fetchStockMovement: 'stock_movements/fetchStockMovement',
    }),
    dayjs,
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.orderParams,
      });
    }, 300),
    async handleDetailStockMovement(id) {
      const res = await this.fetchStockMovement({ id });

      if (res) {
        this.openDetail = false;
        this.openDetailStockMovement = true;
      }
    },
    handleCloseDetailStockMovement() {
      this.openDetailStockMovement = false;
      this.openDetail = true;
    },
    onPageChangeOrder(page) {
      this.fetchOrders({
        ...page,
        ...this.orderParams,
      });
    },
    filterByCompleted() {
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'orders',
          id: data.id,
          attributes: {
            is_valid_for_payment: true,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrders({
            ...this.orderParams,
          });
        }
      });
    },
    handleChangeFilter() {
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    togglePaymentAccordion(id) {
      this.paymentAccordion.active =
        this.paymentAccordion.active === id ? null : id;

      if (this.paymentAccordion.active === id) {
        this.fetchPaymentPaymentMethods({
          id,
          include: 'office-bank',
          fields: {
            'payment-methods': 'payment_amount,office-bank',
            'office-banks': 'bank_name',
          },
        });
      }
    },
    onClickRow(data) {
      this.fetchStockMovementsByOrder({
        orderId: data.attributes.order_id,
      });
      this.fetchOrder({
        id: data.attributes.order_id,
        include:
          'order-details,origin-office,destination-office,origin-warehouse,destination-warehouse,payments,province,village,city,district',
      }).then((res) => {
        this.openDetail = !this.openDetail;
        this.dataPembelian = res.data.data;
      });
    },
    sum(data, key) {
      return data.reduce((a, b) => a + parseFloat(b[key] || 0), 0);
    },
    getRelationships(resource, id) {
      let data = resource.included?.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    getKekurangan(data) {
      const realOrder = this.getOrders.included.find(
        (it) => it.id == data.attributes.order_id
      );
      return (
        realOrder.attributes.payment_amount - realOrder.attributes.final_price
      );
    },
    handleRefresh: function () {
      this.openDetail = false;
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    handleEdit: function (id) {
      this.$router.push(this.indexPath + '/' + id);
    },
  },
};
</script>
