<script>
import BaseInput from '../base/BaseInput.vue';
import BaseCard from '@/components/base/BaseCard.vue';

export default {
  name: 'OrderSummary',
  components: {
    BaseInput,
    BaseCard,
  },
  props: {
    totalbarang: {
      type: Number,
      default: 0,
    },
    dpp: {
      type: Number,
      default: 0,
    },
    ppn: {
      type: Number,
      default: 0,
    },
    totalharga: {
      type: Number,
      default: 0,
    },
    totalBayar: {
      type: Number,
      default: 0,
    },
    cashback: {
      type: Number,
      default: 0,
    },
    balance: {
      type: Number,
      default: 0,
    },
    withCashback: {
      type: Boolean,
      default: false,
    },
    withDpp: {
      type: Boolean,
      default: true,
    },
    withPpn: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    kekurangan() {
      return Math.max(
        this.totalharga -
          (this.totalBayar +
            (this.withCashback ? this.cashback : 0) +
            this.balance),
        0
      );
    },
    kelebihan() {
      return Math.max(
        this.totalBayar + this.cashback + this.balance - this.totalharga,
        0
      );
    },
  },
  watch: {
    totalbarang: function (val) {
      this.$emit('totalbarang', val);
    },
    dpp: function (val) {
      this.$emit('dpp', val);
    },
    ppn: function (val) {
      this.$emit('ppn', val);
    },
    totalharga: function (val) {
      this.$emit('totalharga', val);
    },
    catatan: function (val) {
      this.$emit('catatan', val);
    },
  },
};
</script>
<template>
  <BaseCard>
    <div class="grid grid-cols-2 gap-2">
      <base-input
        label="Total Barang"
        name="totalbarang"
        :value="totalbarang | toCurrency"
        inset
        fullwidth
        disabled
        with-label
      />
      <base-input
        v-if="withDpp"
        label="DPP"
        name="dpp"
        :value="dpp | toCurrency"
        inset
        fullwidth
        disabled
        with-label
      />
      <base-input
        v-if="withCashback"
        label="Cashback"
        name="cashback"
        :value="cashback | toCurrency"
        inset
        fullwidth
        disabled
        with-label
      />
      <base-input
        v-if="withPpn"
        label="PPN (%)"
        name="ppn"
        :value="ppn | toCurrency"
        inset
        fullwidth
        disabled
        with-label
      />
      <div v-if="withCashback"></div>
      <base-input
        label="Total Belanja"
        name="totalbelanja"
        :value="totalharga | toCurrency"
        inset
        fullwidth
        disabled
        with-label
      />
      <div></div>
      <base-input
        v-if="kekurangan > 0"
        label="Total Kekurangan"
        name="kekurangan"
        :value="kekurangan | toCurrency"
        inset
        fullwidth
        disabled
        with-label
      />
      <base-input
        v-if="kelebihan > 0"
        label="Total Kelebihan"
        name="kelebihan"
        :value="kelebihan | toCurrency"
        inset
        fullwidth
        disabled
        with-label
      />
    </div>
  </BaseCard>
</template>
