<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard>
      <div class="flex flex-col">
        <div class="flex items-end justify-end space-x-4">
          <base-input label="Status" with-label>
            <base-select
              :shadow="false"
              :expand="false"
              :options="statusOptions"
              v-model="filter.status"
              v-on:change="handleFilter"
            />
          </base-input>
          <MitraPeriodMonthSelect
            :disabled="filter.disablePeriod"
            v-model="filter.mitraPeriodId"
            v-on:change="handleFilter"
          />
          <base-input label="Cari" with-label>
            <base-search
              placeholder="Cari Kode"
              v-model="search"
              v-on:input="debounceSearch"
            />
          </base-input>
          <div>
            <router-link
              id="add-distributor"
              to="daftar-member/tambah-member"
              type="button"
              class="focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              Tambah Mitra Usaha
            </router-link>
          </div>
        </div>
        <div class="my-2 flex sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
          >
            <Datatable
              :total="getMembers.meta.page.total"
              :perPage="getMembers.meta.page.perPage"
              :currentPage="getMembers.meta.page.currentPage"
              :meta="getMembers.meta"
              @pagechanged="onPageChangeProducts"
              cursor
            >
              <template v-slot:thead>
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nomor Mitra Usaha
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Nama Mitra Usaha
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. HP
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No. Rek
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      No/Nama Upline
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      KTA
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Bulan Bonus
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status Ekspor
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <div v-if="isLoading" class="flex items-center text-center">
                  <loading></loading>
                </div>
                <tbody v-if="getMembers.data.length">
                  <tr
                    class="cursor-pointer bg-white hover:bg-green-100"
                    v-for="(data, dataIdx) in getMembers.data"
                    :key="data.id"
                    :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                    @click="detail(getOfficeIncluded(data))"
                  >
                    <td
                      class="cursor-pointer whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
                    >
                      <template v-if="!getOfficeIncluded(data).attributes.code">
                        -
                      </template>
                      <template v-else>
                        {{ getOfficeIncluded(data).attributes.code }}
                        {{
                          getOfficeIncluded(data).attributes.code &&
                          getOfficeIncluded(data).attributes.temp_code
                            ? '/'
                            : null
                        }}
                        {{ getOfficeIncluded(data).attributes.temp_code }}
                      </template>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{ getOfficeIncluded(data).attributes.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <span v-if="getOfficeIncluded(data).attributes.phone">{{
                        getOfficeIncluded(data).attributes.phone
                      }}</span>
                      <span v-else>-</span>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <span
                        v-if="
                          getOfficeIncluded(data).attributes.bank_account_number
                        "
                        >{{
                          getOfficeIncluded(data).attributes.bank_account_number
                        }}</span
                      >
                      <span v-else>-</span>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getOfficeIncluded(data).attributes.referred_by ?? '-'
                      }}
                      <div class="text-xs">
                        {{
                          getOfficeIncluded(data).attributes.referred_by_name ??
                          '-'
                        }}
                      </div>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        getOfficeIncluded(data).attributes.is_kta_need_delivery
                          ? 'Dikirim'
                          : 'Diambil'
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      {{
                        data.attributes.bonus_month
                          ? dayjs()
                              .month(data.attributes.bonus_month - 1)
                              .format('MMMM')
                          : 'Tidak Ada'
                      }}
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                    >
                      <span
                        s
                        v-if="
                          getOfficeIncluded(data).attributes.is_bonus_reported
                        "
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                      >
                        Diekspor
                      </span>
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-800"
                      >
                        Tidak Diekspor
                      </span>
                    </td>
                    <td
                      class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                    >
                      <span
                        v-if="
                          getOfficeIncluded(data).attributes.is_draft &&
                          !getOfficeIncluded(data).attributes.is_active
                        "
                        class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-800"
                        >Draft</span
                      >
                      <span
                        v-else-if="
                          !getOfficeIncluded(data).attributes.is_active &&
                          !getOfficeIncluded(data).attributes.is_draft
                        "
                        class="inline-flex items-center rounded-full bg-blue-100 px-3 py-1 text-sm font-medium text-blue-800"
                        >Diproses Kasir</span
                      >
                      <span
                        v-else
                        class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                        >Aktif</span
                      >
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr class="bg-white">
                    <td
                      class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                    >
                      Total Mitra Usaha
                    </td>
                    <td
                      colspan="9"
                      class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                    >
                      {{ getMembers.meta.page.total }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </Datatable>
          </div>
        </div>
      </div>
    </BaseCard>
    <!-- MODAL VALIDASI KEMASAN-->
    <transition
      name="customFade"
      enter-active-class="fadeIn"
      leave-active-class="fadeOut"
    >
      <div
        v-if="openAddUser"
        style="animation-duration: 0.3s"
        class="fixed inset-0 z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="mx-4 flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <form @submit.prevent="addUser">
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3
                    class="text-lg font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Buat User
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      Lengkapi form dibawah ini untuk membuat user member
                    </p>
                  </div>
                </div>
                <div class="my-4 space-y-3">
                  <div class="field-inset-default w-full">
                    <label
                      for="email"
                      class="block text-xs font-bold text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      v-model="email"
                      type="email"
                      name="email"
                      id="email"
                      class="block w-full border-0 p-0 text-gray-900 placeholder-gray-200 focus:ring-0 sm:text-sm"
                      placeholder="Masukkan email"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:flex sm:flex-row sm:gap-x-3">
                <button
                  type="submit"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                >
                  Ya
                </button>
                <button
                  @click="openAddUser = !openAddUser"
                  type="button"
                  class="focus:outline-none mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="success"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        id="modal_success"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
              >
                <svg
                  class="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading"> Data Berhasil disimpan </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <base-button
                  color="yellow"
                  fullwidth
                  @click="closeModal"
                  type="button"
                >
                  Selesai
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div
        v-if="failed"
        class="fixed inset-0 z-10 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        id="modal_error"
      >
        <div
          class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
          >
            <div>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <slot name="heading">
                  {{ getError }}
                </slot>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <div class="flex items-center gap-2">
                <button
                  @click="closeModal"
                  class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Ulangi
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import MitraPeriodMonthSelect from '@/components/period/mitra-period/mitra-period-month-select.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import Loading from '@/components/Loading.vue';
import BaseSearch from '@/components/base/Search.vue';
import dayjs from 'dayjs';

export default {
  name: 'Users',
  components: {
    Datatable,
    BaseSelect,
    MitraPeriodMonthSelect,
    BaseInput,
    Loading,
    BaseSearch,
  },
  data() {
    return {
      search: null,
      disablePeriod: false,
      success: false,
      failed: false,
      openAddUser: false,
      roles_id: null,
      selectedMember: null,
      email: null,
      filter: {
        status: null,
        mitraPeriodId: null,
      },
    };
  },
  async created() {
    await this.loadCurrentPeriod();

    this.loadMembers();
    this.fetchRoles({
      search: 'User Member',
    }).then((response) => {
      if (response.data.data.length) {
        this.roles_id = response.data.data[0].id;
      }
    });
  },
  computed: {
    ...mapGetters({
      getMembers: 'simple_offices/getUplineSimpleOffices',
      isLoading: 'simple_offices/getLoading',
      me: 'auth/getUser',
    }),
    fetchMembersParams: function () {
      return {
        'page[limit]': 5,
        sort: '-id',
        include: 'office',
        'fields[simple-offices]': 'bonus_month,office',
        'fields[offices]':
          'code,temp_code,name,phone,bank_account_number,is_kta_need_delivery,is_draft,is_bonus_reported,is_active,email,referred_by,referred_by_name',
        'filter[office_category_id]': 5,
        'filter[period_uuid]': this.filter.mitraPeriodId,
        'filter[search]': this.search,
        ...(this.filter.status
          ? this.filter.status === 'draft'
            ? { 'filter[is_draft]': true, 'filter[is_active]': false }
            : this.filter.status === 'process_cashier'
            ? { 'filter[is_draft]': false, 'filter[is_active]': false }
            : this.filter.status === 'active'
            ? { 'filter[is_active]': true }
            : {}
          : {}),
      };
    },
    statusOptions: function () {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        {
          key: 'draft',
          value: 'draft',
          label: 'Draft',
        },
        {
          key: 'process_cashier',
          value: 'process_cashier',
          label: 'Proses Kasir',
        },
        {
          key: 'active',
          value: 'active',
          label: 'Aktif',
        },
      ];
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchRoles: 'roles/fetchRoles',
      createUser: 'users/createUser',
      fetchCurrentPeriod: 'mitra_periods/fetchCurrentMitraPeriod',
      fetchMembersByUpline: 'simple_offices/fetchSimpleOfficesByUpline',
    }),
    loadMembers(params) {
      this.fetchMembersByUpline({
        uplineId: this.me.office_id,
        params: {
          ...this.fetchMembersParams,
          ...params,
        },
      });
    },
    debounceSearch: debounce(function () {
      this.loadMembers();
    }, 300),
    onPageChangeProducts(page) {
      this.loadMembers(page);
    },
    detail(data) {
      this.$router.push(`daftar-member/${data.id}`);
    },
    closeModal() {
      this.success = this.failed = this.openAddUser = false;
      this.loadMembers();
    },
    addUser() {
      const payload = {
        data: {
          type: 'users',
          attributes: {
            name: this.selectedMember.attributes.name,
            email: this.email,
          },
          relationships: {
            office: {
              data: {
                type: 'offices',
                id: this.selectedMember.id,
              },
            },
            roles: {
              data: [
                {
                  type: 'roles',
                  id: this.roles_id,
                },
              ],
            },
          },
        },
      };
      this.createUser(payload).then((response) => {
        response ? (this.success = true) : (this.failed = true);
      });
    },
    handleFilter: function () {
      const disablePeriod = ['process_cashier', 'draft'];
      if (disablePeriod.includes(this.filter.status)) {
        this.filter.mitraPeriodId = null;
        this.filter.disablePeriod = true;
      } else {
        this.filter.disablePeriod = false;
      }
      this.loadMembers();
    },
    async loadCurrentPeriod() {
      const res = await this.fetchCurrentPeriod({
        'fields[mitra-periods]': 'month,year,name',
      });

      this.filter.mitraPeriodId = res.data.data.id;
    },
    getOfficeIncluded(data) {
      return this.getSingleIncluded(
        this.getMembers,
        data.relationships.office.data.id
      );
    },
  },
};
</script>
